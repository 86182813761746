
              @import "@/assets/css/variables.scss";
            



































































































































































































































































































































































































































// @import '@/assets/css/pages/leads.scss';
// @import '@/assets/css/pages/rebatePaymentHistory.scss';
// @import '@/assets/css/components/pagination.scss';
.history_rebate {
  margin-top: 16px;
}
